import React, { useEffect, useState } from 'react';
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import Grid from '@material-ui/core/Grid';
import PageTitle from '../PageTitle/PageTitle';
import HadithSection from '../HadithSection/HadithSection';
import SetionTitle from "../SetionTitle/SetionTitle";
import DonationCardTwoComp from "../SidebarWidgets/DonationCardTwo";
import DonateBanner from '../DonateBanner/DonateBanner';
import img from '../../assets/imgs/readimg.png';
import Services from '../Services/Services';
import PromotionalSection from '../PromotionalSection/PromotionalSection';
import '../../assets/css/style.css';
import { Section, CardTwo, TabOne, ButtonOne, SideBarBody } from "master-react-lib";
import VideoListingSidebar from '../SidebarWidgets/Videos';
import FacebookFeeds from "../FacebookFeeds/FacebookFeeds";
import { getNews } from '../../functions';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function ReadsViewsPage() {

    const [recordsFetchIndex, setRecordsFetchIndex] = useState(3);
    const [totalRecords, setTotalRecords] = useState(0);

    const fetchNews = async () => {
        const news = await getNews(orgId, 'deenweb');
        setTotalRecords(news.data.body.data.length);
        return news;
    }

    useEffect(async () => {
        let news = await fetchNews();
        let tempNews = [];

        if (news && news.data.body.data && Array.isArray(news.data.body.data)) {
            //get the news items and process them into array 
            news.data.body.data.filter( x => x.status === "Public" ).map(element => {
                if (tempNews.length < 3) {
                    let object = {
                        name: element.article?.author,
                        date: element.article?.createdAt, // 'MAY 05, 2020',
                        img: element.article.imageMetaData?.imageUrl,
                        title: element.article.title.substring(0, 50),
                        Discription: JSON.parse(element.article.description)[0].children[0].text.substring(0, 100) + '...',
                        link: "/reads-details?q=" + element.article._id 
                    };
                    tempNews.push(object);
                }
            });
            setcardTwoData([tempNews[0]]);

            if (tempNews[2]) {
                setCardGridData([tempNews[1], tempNews[2]]);
            } else {
                setCardGridData([tempNews[1]]);
            }

            setlatestReadsData(tempNews);

            setPastReadsData(tempNews);

        }
    }, []);


    const [cardTwoData, setcardTwoData] = useState([]);

    const [cardGridData, setCardGridData] = useState([]);


    const [latestReadsData, setlatestReadsData] = useState([]);

    const [pastReadsData, setPastReadsData] = useState([]);


    const tabItems = [
        {
            title: "LATEST READS",
            body: <div><CardTwo
                cardTwoData={latestReadsData}
                col="4"
                Imgcol="12"
                Contentcol="12"
                spacing={2}
                buttonLabel="READ MORE"
                hoverbackground="#0052a5"
                titleColor="#0053a5"
            /></div>,
        },

        {
            title: "PAST READS",
            body: <div><CardTwo
                cardTwoData={pastReadsData}
                col="4"
                Imgcol="12"
                Contentcol="12"
                spacing={2}
                buttonLabel="READ MORE"
                hoverbackground="#0052a5"
                titleColor="#0053a5"
            /></div>,
        },


    ];

    const click = async (e) => {
        e.preventDefault();
        let news = await fetchNews();
        let tempNews = [];

        if (news && news.data.body.data && Array.isArray(news.data.body.data)) {
            //get the news items and process them into array 
            news.data.body.data.filter( x => x.status === "Public" ).map(element => {
                    let object = {
                        name: element.article?.author,
                        date: element.article?.createdAt, // 'MAY 05, 2020',
                        img: element.article.imageMetaData?.imageUrl,
                        title: element.article.title.substring(0, 50),
                        Discription: JSON.parse(element.article.description)[0].children[0].text.substring(0, 100) + '...',
                        link: "/reads-details?q=" + element.article._id 
                    };
                    tempNews.push(object);
            });
            setcardTwoData([tempNews[0]]);

            if (tempNews[2]) {
                setCardGridData([tempNews[1], tempNews[2]]);
            } else {
                setCardGridData([tempNews[1]]);
            }

            setlatestReadsData(tempNews);
            setPastReadsData(tempNews);
            setRecordsFetchIndex(news.data.body.data.length);

        }

    }

    return (

        <div>
            <Header />



            <div className="MainWrapper">

                <PageTitle
                    Title="READS/VIEWS"
                />

                <HadithSection />


                <Section
                    background="#F4F9FC"
                    //ContainerWidth=""
                    Spacing="40px"

                    Container={
                        <div>
                            <SetionTitle
                                TitleLight="LATEST"
                                TitleBold="READS/VIEWS"
                                ButtonStatus={false}
                            />
                            <Grid container spacing={3}>

                                <Grid item md={8} sm={8} xs={12}>

                                    <Grid container spacing={3}>

                                        <Grid item md={12} sm={12} xs={12}>
                                            <div className="readsCardTOp">
                                            <CardTwo
                                                cardTwoData={cardTwoData}
                                                col="12"
                                                Imgcol="6"
                                                Contentcol="6"
                                                spacing={3}
                                                buttonLabel="READ MORE"
                                                hoverbackground="#0052a5"
                                                titleColor="#0053a5"
                                            />
                                            </div>
                                        </Grid>

                                        <Grid item md={12} sm={12} xs={12}>
                                        <div className="readsCardTOp">
                                            <CardTwo
                                                cardTwoData={cardGridData}
                                                col="6"
                                                Imgcol="12"
                                                Contentcol="12"
                                                spacing={3}
                                                buttonLabel="READ MORE"
                                                hoverbackground="#0052a5"
                                                titleColor="#0053a5"
                                            />
                                            </div>
                                        </Grid>

                                    </Grid>



                                    <Grid container spacing={3}>

                                        <Grid item md={12} sm={12} xs={12}>
                                            <div className='readtabContainer'>
                                                <TabOne
                                                    tabItems={tabItems}
                                                    tabBody={true}

                                                />
                                            </div>

                                            {
                                                recordsFetchIndex < totalRecords
                                                ?
                                                <div style={{ display: "flex", justifyContent: "center", marginBottom: "40px", }} className='downicon'>
                                                    <ButtonOne
                                                        label="LOAD MORE"
                                                        onClick={ (e) => click(e) }
                                                        link="#"
                                                        //target="blank"
                                                        path="/"

                                                        arrowIcon={<ArrowForwardIosRoundedIcon />}
                                                        arrowStatus={true}
                                                        arrowColor="#0B132B"

                                                        buttoncolor="#F4F9FC"
                                                        width=""
                                                        height="50px"
                                                        border="#0053A5 1px solid"
                                                        borderRadius="4px"
                                                        color="#0B132B"
                                                        fontWeight="600"
                                                        textAlign="center"
                                                    />
                                                </div>
                                                : null
                                            }

                                            

                                            <DonateBanner />
                                        </Grid>

                                    </Grid>



                                </Grid>

                                <Grid item md={4} sm={4} xs={12}>

                                    <Grid container spacing={3}>

                                        <Grid item md={12} sm={12} xs={12}>
                                            <FacebookFeeds />
                                        </Grid>

                                        <Grid item md={12} sm={12} xs={12}>
                                            <DonationCardTwoComp />
                                        </Grid>

                                        <Grid item md={12} sm={12} xs={12}>
                                            <SideBarBody
                                                background="#fff"
                                                border="#E4E8EC 1px solid"
                                                SideBarBody={
                                                    <VideoListingSidebar />
                                                }
                                            />
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </Grid>

                        </div>
                    }
                />



            </div>

            <Services />
            <DonateBanner />
            {/* <div className='PromotionalSectionBorder'>
                <PromotionalSection />
            </div> */}

            <Footer />
        </div>
    );
}


export default ReadsViewsPage;