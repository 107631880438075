import React, { useEffect, useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

import ReadandViews from "../ReadandViews/ReadandViews";
import BannerSection from "../HomeBanner/BannerSection";
import DonateBanner from "../DonateBanner/DonateBanner";
import BuildProject from "../BuildProject/BuildProject";
import UpcomingEvents from "../UpcomingEvents/UpcomingEvents";
import VideosSection from "../VideosSection/VideosSection";
import PromotionalSection from "../PromotionalSection/PromotionalSection";
import Services from "../Services/Services";
import ReserveYourToday from "../ReserveYourToday/ReserveYourToday";

import "../../assets/css/style.css";
import { getSalahs } from "../../functions";
import CTAEightSection from "../DonationSection/CTAEightSection";

function Home() {
  useEffect(async () => {}, []);

  const [allsalahs, settableBodyData] = useState([]);

  return (
    <div>
      <Header />
      <div className="MainWrapper">
        <BannerSection />
        <ReadandViews />
        <DonateBanner />
        {/* <BuildProject /> */}
        <VideosSection />
        <CTAEightSection />
        <UpcomingEvents />
        <DonateBanner />
        {/* <PromotionalSection /> */}
        <Services />
        <ReserveYourToday />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
